import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import Zoom from 'react-reveal/Zoom';
import NavBars from '../NavBars/navBars';

import './header.css';

// const Header = (props) => {
class Header extends Component {
  
  constructor (props) {
    super(props);

    this.state = {
      showMenu: false
    }
    
  }

  logo = () => (
    <Link to="/" className="logo">
      <img src="/images/C_Logo.svg" alt="colin_logo" />
    </Link>
  )

  showMenu = () => {
    this.setState( { 
      showMenu: !this.state.showMenu 
    });
  }

  mobileMenu = () => (
    <ul className="mobile-menu">
      <li><a href="#hero">home</a></li>
      <li><a href="#work">work</a></li>
      <li><a href="#about">about</a></li>
      <li><a href="#contact">contact</a></li>
    </ul>
  )

  desktopMenu = () => (
    <ul className="desktop-menu">
      <li><a href="#hero">home</a></li>
      <li><a href="#work">work</a></li>
      <li><a href="#about">about</a></li>
      <li><a href="#contact">contact</a></li>
    </ul>
  )

  render() {
    let menuStatus = this.state.showMenu ? 'open' : 'closed';

    return (
      <Zoom>
        <header>
          <div className="header-inner">
            {this.logo()}
            <NavBars 
              type="navbars"
              showMenu={() => this.showMenu()}
            />
            <div className={menuStatus}>
              {this.mobileMenu()}
            </div>
            {this.desktopMenu()}
          </div>
        </header>
      </Zoom>
    )
  }

}

export default Header;