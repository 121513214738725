import React, { Component } from 'react';
import { firebaseSocials, firebaseLooper } from '../../firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './socials.css';

class Socials extends Component {

  state = {
    items: []
  }

  // Runs server request on load
  componentWillMount() {
    this.request();
  }

  // Do a server request
  request = () => {

    firebaseSocials.once('value')
    .then((snapshot) => {
      const stuff = firebaseLooper(snapshot);
      
      this.setState({
        items: [...stuff]
      })
    })
  }

  renderSocials = (type) => {
    let template = null;
    
    switch (type) {
      case 'list':
        template = this.state.items.map((item, i) => (
          <li key={i}>
            <a href={item.url} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={['fab', `${item.icon}`]} />
            </a>
          </li>
        ));
        break;
      default:
        template = null;
    }
    return template;
  }

  email = () => (
    <li>
      <a href="mailto:colinsweeney2000@gmail.com">
        <FontAwesomeIcon icon="envelope" />
      </a>
    </li>
  )

  render() {
    return (
      <div>
        <ul className="social-list">
          { this.renderSocials(this.props.type) }
          { this.email() }
        </ul>
      </div>
    )
  }
}

export default Socials;