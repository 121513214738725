import React from 'react';
import ReactDOM from 'react-dom';

import { configureAnchors } from 'react-scrollable-anchor';
import { BrowserRouter } from 'react-router-dom';

import './firebase';

import Routes from './routes';
import './basic.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faInstagram, faGithub } from '@fortawesome/free-brands-svg-icons';

library.add(
  faLinkedin, faInstagram, faGithub, faEnvelope, faChevronUp
);

configureAnchors({
  scrollDuration: 1500 
});



const App = () => {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  )
}

ReactDOM.render(<App />, document.getElementById('root'));