import React, { Component } from 'react';
import { firebaseWork, firebaseLooper } from '../../../firebase';

// import axios from 'axios';
// import { URL } from '../../../functions';

import './workList.css';

import Button from '../Buttons/buttons';

class WorkList extends Component {

  state = {
    items: [],
    start: this.props.start,
    end: this.props.start + this.props.amount,
    amount: this.props.amount
  }

  // Runs server request on load
  componentWillMount() {
    this.request(this.state.start, this.state.end);
  }

  // Do a server request
  request = (start, end) => {

    firebaseWork.orderByChild('id').startAt(start).endAt(end).once('value')
      .then((snapshot) => {
        const works = firebaseLooper(snapshot);
        this.setState({
          items: [...this.state.items, ...works],
          start,
          end
        })
      }).catch(e => {
        console.log(e);
      })

  }
  
  
  loadMore = () => {
    let end = this.state.end + this.state.amount;
    this.request(this.state.end + 1, end);
  }
  
  // Display ther template
  renderWork = (type) => {
    let template = null;
    
    switch (type) {
      case 'listItem':
        template = this.state.items.map((item, i) => (
          <li key={i}>
            <a href={ item.url } target="_blank" rel="noopener noreferrer" 
              >
              <div className="work-info">
                <img src={`../images/${item.image}`} alt={item.title} />
                <ul className="skills">
                  {
                    [
                      item.skills.map((x, j) =>
                      <li key={j}>
                        { j > 0 && ' / '}
                        {x}
                      </li>
                    )]
                  }
                </ul>
              </div>
            </a>
          </li>
        ));
        break;
      default:
        template = null;
    }
    return template;
  }

  render() {    
    return (
      <section className="work">
        <div className="work-inner">
          <h3>work</h3>
          <ul className="inner-content">
            {this.renderWork(this.props.type)}
          </ul>
          { this.state.end <= this.state.items.length &&
            <Button 
              type="loadmore"
              loadMore={() => this.loadMore()}
              cta="Load more"
            />
          }
        </div>
      </section>
    )

  }
}

export default WorkList;
