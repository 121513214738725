import React from 'react';
import ScrollableAnchor from 'react-scrollable-anchor';
import Zoom from 'react-reveal/Zoom';

import './about.css';

const About = () => {

  const aboutText = () => (
    <div className="about-text">
      My name is Colin Sweeney. I am a web developer.<br /><br />

      I graduated from IT Högskolan in Gothenburg, Sweden 2018.<br />
      During the 2 year course (named Web development with CMS) I learned a wide and varied range of skills and techniques.<br />
      Some of the CMS platforms I studied include:-<br />
      <ul>
        <li>Wordpress</li>
        <li>Drupal</li>
        <li>Magento</li>
        <li>Laravel</li>
      </ul>
      Other languages/techniques I learned include:-<br />
      <ul>
        <li>PHP</li>
        <li>Javascript (Vanilla, Jquery, Angular, Polymer, NodeJs)</li>
        <li>Adobe Photoshop, Adobe XD</li>
        <li>Agile (Scrum, Kanban, etc)</li>
      </ul>
      My main interests lately are React & VueJs.
    </div>
  )

  const aboutImg = () => (
    <div className="about-img">
      <img src="images/about_img.jpg" alt="about-img" />
    </div>
  )

  return (
    <Zoom>
      <ScrollableAnchor id={'about'}>
        <section className="about">
          <div className="about-inner">
            <h3>about</h3>
            <div className="inner-content">
              { aboutText() }
              { aboutImg() }
            </div>
          </div>
        </section>
      </ScrollableAnchor>
    </Zoom>
  )
}

export default About;