import React from 'react';

// import all sections here
import About from '../About/about'
import Contact from '../Contact/contact';
import Work from '../Work/work';
import Hero from '../Hero/hero';

const Home = () => {
  return (
    <main>

      <Hero />
      <Work />
      <About />
      <Contact />      
    </main> 
  )
}

export default Home;