import React from 'react';

import './navBars.css';

const MobileMenu = (props) => {

  let template = null;

  switch (props.type) {
    case 'navbars':
      template = (
        <div className="bars"
          onClick={props.showMenu}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      );
      break;
    default:
      template = null;
  }
  return template;
}

export default MobileMenu;

