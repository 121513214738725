import * as firebase from 'firebase';

// Config section
const firebaseConfig = {
  apiKey: "AIzaSyDlQseUKQ95SrSsaPOe8fmIcXPqBOplAco",
  authDomain: "colinsweeneyreact.firebaseapp.com",
  databaseURL: "https://colinsweeneyreact.firebaseio.com",
  projectId: "colinsweeneyreact",
  storageBucket: "colinsweeneyreact.appspot.com",
  messagingSenderId: "595638592003",
  appId: "1:595638592003:web:c4daecd105f15755396436",
  measurementId: "G-918HC20QYB"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Some variables
const firebaseDB = firebase.database();
const firebaseWork = firebaseDB.ref('work');
const firebaseSocials = firebaseDB.ref('socials');

const firebaseLooper = (snapshot) => {
  const data = [];

  snapshot.forEach((childSnapshot) => {
    data.push({
      ...childSnapshot.val(),
      id: childSnapshot.key
    })
  });
  return data;
}

export {
  firebase,
  firebaseDB,
  firebaseWork,
  firebaseSocials,
  firebaseLooper
}
