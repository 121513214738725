import React from 'react';
import ScrollableAnchor from 'react-scrollable-anchor';
import Zoom from 'react-reveal/Zoom';

import WorkList from '../Widgets/WorkList/workList';

const Work = () => {

    return (
      <Zoom>
        <ScrollableAnchor id={'work'}>
          <WorkList
            type="listItem"
            loadmore={true}
            start={0}
            amount={3}
          />
        </ScrollableAnchor>
      </Zoom>
    )

}

export default Work;