import React from 'react';
import ScrollableAnchor from 'react-scrollable-anchor';

import './hero.css';

const Hero = () => {
  return (
    <ScrollableAnchor id={'hero'}>
      <div className="hero-outer">
        <section className="hero">
          <h2>colin sweeney</h2>
          <h1>web developer</h1>
        </section>
      </div>
    </ScrollableAnchor>
  )
}

export default Hero;