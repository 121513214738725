import React from 'react';
import { goToTop } from 'react-scrollable-anchor';
import Zoom from 'react-reveal/Zoom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import style from './footer.css';
import './footer.css';

import { CURRENT_YEAR } from '../../functions';

const Footer = () =>  (
  <Zoom>
    <footer className="footer">
      <div className="text">
        © Colin Sweeney {CURRENT_YEAR}
      </div>
      <button onClick={goToTop}><FontAwesomeIcon icon="chevron-up" /><br />Go To Top</button>
    </footer>
  </Zoom>
);

export default Footer;