import React from 'react';
import ScrollableAnchor from 'react-scrollable-anchor';
import Zoom from 'react-reveal/Zoom';

import Socials from '../Socials/socials';

import './contact.css';

const Contact = () => {

  const contactText = () => (
    <div className="contact-text"> 
      Get in touch.<br /><br />

      Either by <a href="mailto:colinsweeney2000@gmail.com">email</a>…<br /><br />

      or by social media (click on the links below)…<br /><br />
    </div>
  )

  return (
    <Zoom>
      <ScrollableAnchor id={'contact'}>
        <section className="contact">
          <div className="contact-inner">
            <h3>contact</h3>
            <div className="inner-content">
              { contactText() }
              <Socials 
                type="list"
              />
            </div>
          </div>
        </section>
      </ScrollableAnchor>
    </Zoom>
  )
}

export default Contact;